/* Reset Styles */

.col-wrap {
  background: $dark;
  padding: 20px;
  .task-count {
    margin-bottom: 10px;
  }
  .task-card {
    background: $card-bg;
    width: 100%;
    border-radius: 0px;
    padding: 20px;
    display: grid;
    position: relative;
    margin-bottom: 15px;
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: grab;
  }
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
  }
  .dropdown {
    .dropdown-toggle {
      border: none;
      background: transparent;
      color: $text-muted;
    }
  }
  .add-card {
    border: none;
    color: $text-muted;
    font-weight: bold;
  }
  .card-image {
    width: 100%;
  }
}
.image-grouped {
  display: flex;
  margin-bottom: 10px;
  img {
    border-radius: 100%;
    margin-left: -10px;
    z-index: 0;
    border: 4px solid $dark;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-property: "box-shadow", "z-index", "transform", "border-width", "box-shadow", "transform";
    transition-property: "box-shadow", "z-index", "transform", "border-width", "box-shadow", "transform";
    transition-property: "box-shadow", "z-index", "transform", "border-width";
    width: 35px;
    height: 35px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      z-index: 1;
      box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.5);
      transform: scale(1.05);
      border-width: 0;
    }
  }
}