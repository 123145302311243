.user-lidting-thumb {
  position: relative;
  margin-right: 1.5rem;
  max-width: 100px;
  img {
    border-radius: 100%;
  }
  .edit-thumb-icon {
    position: absolute;
    bottom: 8%;
    right: -10px;
    height: 35px;
    width: 35px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 3px solid $border-color;
    background: $content-bg;
    font-size: 1.25rem;
    line-height: 1rem;
    color: $info;
  }
}
.user-details {
  margin-right: 1.5rem;
}
.feature-icon {
  padding: .5rem;
  border-radius: 100%;
  border: 1px solid $border-color;
  font-size: 1.5rem;
  margin-right: .5rem;
  color: $text-muted;
}